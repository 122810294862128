.secTitle{
    /* height: 10%; */
    /* background-color: #252525; */
    border-top: 3px solid #333333;
    border-bottom: 3px solid #333333;
    justify-content: center;
    font-size: 3rem;
    padding: 5%;
}
.do{
    padding: 5%;
}
.doTitle{
    font-size: 18px;
    line-height: 35px;
}
.doText{
    font-size: 12px;
    line-height: 30px;
}
.sec{
    display: flex;
    margin-top: 3%;
}
.sec > img{
    height: 20%;
}
.prog-con{
    margin-bottom: 15px;
}
.prog-top{
    display: flex;
    justify-content: space-between;
}
.bar-outer{
    border: 1px solid #444444;
    height: 15px;
    border-radius: 15px;
}
.bar-inner{
    width:80%;
    background-color: #007ced;
    height: 100%;
    border-radius: 15px;
}

.sPo, .sPo2{
    border: 1px solid #444444;
    height: 3px;
    width:20%;
    border-radius: 15px;
}
.sPo2{
    width:100%
}
.sPi{
    width:40%;
    background-color: #007ced;
    height: 100%;
    border-radius: 15px;
}