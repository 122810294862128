.card{
    font-family: 'Poppins', sans-serif;
    background-color: #222222;
    color: #ffffff;
    border: 3px solid #333;
    padding: 1%;
}

.cardText{
    display: block;
    overflow: hidden;
    max-height: 10rem;
    font-weight: 500;

    font-size: 15px;
}

.postDate{
    font-size: smaller;
}
.blog-img-bg{
    height: 10rem;
}
.blog-img{
    height: 100%;
    width: 100%;
}
.card-footer{
    display: flex;
    justify-content: space-between;
}
.footerImage{
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
}
.card-title{
    font-size: large;
    font-weight: bolder;
}

.cTitle{
    height: 8rem;
    overflow: hidden;
}
.btn4{
    text-align: center;
    padding-top: 5%;
    width: 100%;
}

@media only screen and (max-width: 600px) {
  .card{
    width: 80%;
  }
}