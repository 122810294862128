@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* { 
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */

}
body{
  min-height: 100vh;
}
.container{
  min-height: 100vh;
}

.App {
  /* text-align: center; */
  font-family: 'Poppins', sans-serif;
  /* background-color: #222222; */
  color: #ffffff;
  min-height: 100vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:link {
  text-decoration: none;
  color:#ffffff
}

a:visited {
  text-decoration: none;
  color:#ffffff
}
.skill{
  cursor: pointer;
}
a:hover, .skill:hover {
  text-decoration: none;
  font-weight: bolder;
}

a:active {
  text-decoration: none;
}