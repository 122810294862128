nav{
    padding: 3%;
    display: flex;

    justify-content: space-between;
}
.navBars, .navBars2{
    width: 50%;
}
.hambuger{
    display: none;
}
.hbg-btn{
    display: transparent;
    background-color: none;
}

.dropdown-content{
    position:absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: #222222;
    padding: 12px 20px;
    z-index: 1;
}

.skt{
    padding-top: 1rem;
}

@media only screen and (max-width: 1000px)  {
    .pjs > ul{
        font-size:smaller;
        justify-self: center;
        justify-content: space-between;
    }
    .hambuger{
        display: block;
        position: absolute;
        right: 3%;
        cursor: pointer;
        z-index: 999;
    }
    .navBars > ul{
        /* background-color: red; */
        display: none;
        padding-top: 10%;
    }
    .navBars2 > ul{
        display: grid;
        padding-top: 5%;
        padding-bottom: 5%;
        position: absolute;
        z-index: 1;
        background-color: #222222;
        width: 50%;
        height: 50vh;
        /* background-color: red; */
    }
    /* .dropdown{
        margin-bottom: 5rem;
    } */

}