.details {
	margin: auto;
	padding: 5%;
}
td {
	color: #fff;
}
.td {
	color: gold;
}

@media only screen and (max-width: 500px) {
	.table {
		margin-left: -3%;
	}
}
