.hero{
    width: 100%;
    padding: 5%;
}


.dp{
    border: 1em solid #444444;
    padding: 1rem;
    border-radius: 50%;
    height: 20rem;
    width: 20rem;
    margin-left: 1rem;
    background-color: #010E1E;
    box-shadow: 10px 10px 5px #010E1E;
}
.dp > img{
    width:100%;
    height: 100%;
    border-radius: 50%;
}

.hero-body{
    padding:5%;
    text-align: left;
}
.title{
    font-size: 1rem;
    line-height: 35px;
    font-weight: 50%;
    font-weight: 100;
}
.name{
    font-size: 2rem;
}
.context{
    font-size: 20;
}
.btn1, .btn2, .btn4{
    width: 7rem;
    height: 3rem;
    border-radius: 25px;
    border: 1px solid #007CED;
    background-color: #333333;
    color: #cccccc;
    margin-top: 3%;
}
.btn1:hover, .btn2:hover, .btn4:hover{
    background-color:#cccccc;
    color: #333333;
    border: none;
}
.btn2{
    border: 1px solid #ffffff;
    margin-left: 5%;
}

@media only screen and (max-width: 600px) {
  .dp{
    height: 10rem;
    width: 10rem;
    margin-left: 20%;
  }

}
