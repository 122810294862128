.map{
    width: 100%;
    height: 10%;
    border:0;
}
.contact-body, .contactRight{
    align-content: flex-start;
}
.contactRight{
    padding-left: 10%;
}
.contact-body{
    margin-top: 30px;
}
.p2{
    padding-top: 20px;
    font-size: 1rem;
}
input, input:focus, input:active, textarea, textarea:focus, textarea:active{
    background: transparent;
    border: 1px solid #555555;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 90%;
    height: 100%;
    padding:0.5rem;
    color: white;
}
textarea, textarea:focus, textarea:active{
    height: 100%;
}
.t1, .t2{
    padding: 5% 0;
    height: 100%;
}
.form{
    height: 100%;
}
.btn3{
    width: 100px;
    height: 40px;
}
.spinner-border{
    margin-top: 3%;
    margin-left: 5%;
}